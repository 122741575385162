<div>
  <div>
    <h1>Oops</h1>
  </div>
  <h5>
    You don't have permission to use StoryHub. Please make sure you are using
    either your <code>purple.telstra.com</code> or
    <code>team.telstra.com</code> account. If the problem persists, please post
    in
    <a
      href="https://web.yammer.com/main/org/team.telstra.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNzc4NDQzOCJ9/all"
      >Purple - StoryHub
    </a>
    and one of the team will work with you to gain access.
  </h5>
</div>

import { EmbeddedLinkType, embeddedLinkTypeDescriptor } from '../../../shared/models/embedded-link-type';
import { Story } from '../../../shared/models/story';
import { StoriesService } from '../../services/stories.service';
import { EditStoryLinksComponent } from '../edit-story-links/edit-story-links.component';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TrackByFunction } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmbeddedLink } from '../../../shared/models/embedded-link';

@Component({
  selector: 'app-view-story-links',
  templateUrl: './view-story-links.component.html',
  styleUrls: ['./view-story-links.component.scss'],
})
export class ViewStoryLinksComponent implements OnChanges {
  private readonly dialogWidth = '700px';

  public readonly embeddedLinkTypeDescriptor = embeddedLinkTypeDescriptor;

  @Output() public afterDialogClosed = new EventEmitter<string>();
  @Input() public story: Story;
  @Input() canEdit: boolean;
  public embeddedLinkType = EmbeddedLinkType;
  public webLinks: any[] = [];

  constructor(private readonly dialog: MatDialog, private readonly storiesService: StoriesService) {}

  public async edit(): Promise<void> {
    if (!this.canEdit) return;
    const dialogRef = this.dialog.open(EditStoryLinksComponent, {
      width: this.dialogWidth,
      data: this.story,
    });

    await dialogRef.afterClosed().toPromise();

    this.afterDialogClosed.emit(this.story.id);
  }

  public async getLinkMetadata(uri: string): Promise<any> {
    return await this.storiesService.getEmbeddedLinkMetadata(uri).toPromise();
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.story) {
      this.webLinks = this.story.embeddedLinks.map((el) => ({
        uri: el.uri,
        label: el.label,
        type: el.type,
      }));
    }
  }

  trackByFn: TrackByFunction<EmbeddedLink> = (index, item) => item.uri + item.label;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportHomeComponent } from './export-home/export-home.component';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [ExportHomeComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class ExportModule {
}

<!-- case study nominated -->
<section class="field">
  <mat-checkbox
    class="example-margin"
    [(ngModel)]="caseStudyNominated"
    (change)="onCaseStudyNominatedChanged($event.checked)"
    >Nominate story as potential case study</mat-checkbox
  >
</section>

<!-- case study nomination -->
<mat-form-field class="field" *ngIf="caseStudyNominated">
  <mat-hint>Customer willingness to participate in case study</mat-hint>
  <mat-select [(value)]="caseStudyNomination">
    <mat-option *ngFor="let key of caseStudyNominationDescriptor.Keys" [value]="key">
      {{ caseStudyNominationDescriptor[key].Label }}
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- case study nomination notes -->
<mat-form-field class="field" *ngIf="caseStudyNominated">
  <textarea
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="4"
    cdkAutosizeMaxRows="10"
    [(ngModel)]="caseStudyNominationNotes"
  ></textarea>
  <mat-hint>Notes for Value Creation Team</mat-hint>
</mat-form-field>

<!-- save / cancel buttons -->
<div mat-dialog-actions>
  <button [disabled]="saveDisabled" class="primaryButton" mat-button color="primary" (click)="save()">SAVE</button>
  <button mat-button (click)="cancel()">CANCEL</button>
</div>

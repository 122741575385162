import { Input, Component, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ReleaseNote } from 'src/app/shared/models/release-note';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ReleaseNotesService } from '../../services/release-note.service';
import { EditReleaseNotesDialogComponent } from '../edit-release-notes/edit-release-notes.dialog';
import { User } from 'src/app/shared/models/user';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogResult,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { confirmationDialogHeight, confirmationDialogWidth, notificationDuration } from 'src/app/shared/constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'release-notes',
  templateUrl: 'release-notes.component.html',
  styleUrls: ['release-notes.component.scss'],
})
export class ReleaseNotesComponent {
  isAdmin: boolean;
  tableSource = new MatTableDataSource<ReleaseNote>();
  rowCount: number;
  releaseNotes: ReleaseNote[];

  currentUser: User;

  constructor(
    private userService: UserService,
    private releaseNotesService: ReleaseNotesService,
    private titleService: Title,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.titleService.setTitle('Release Notes');
  }

  ngOnInit() {
    this.fetchReleaseNotes();

    this.userService.getUser().subscribe((user) => {
      this.isAdmin = user.isAdmin;
    });

    this.userService.getUser().subscribe((user: User | {}) => {
      if (Object.keys(user).length !== 0) {
        this.currentUser = user as User;
      }
    });
  }

  fetchReleaseNotes() {
    this.releaseNotesService.getReleaseNotes().subscribe((notes: ReleaseNote[]) => {
      this.releaseNotes = notes;
      this.rowCount = notes.length;
    });
  }

  openEdit(releaseNote: ReleaseNote) {
    if (this.isAdmin) {
      var dialogRef = this.dialog.open(EditReleaseNotesDialogComponent, {
        width: '50%',
        disableClose: true,
        data: { releaseNote },
      });
      dialogRef.afterClosed().subscribe((updatedReleaseNote: ReleaseNote) => {
        if (updatedReleaseNote) {
          const note = this.releaseNotes.find((r) => r.id === releaseNote.id);
          note.title = updatedReleaseNote.title;
          note.versionId = updatedReleaseNote.versionId;
          note.implementer = updatedReleaseNote.implementer;
          note.lastUpdatedBy = updatedReleaseNote.lastUpdatedBy;
          note.dateLastUpdated = updatedReleaseNote.dateLastUpdated;
          note.content = updatedReleaseNote.content;
        }
      });
    }
  }

  deleteReleaseNote(id: string) {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: confirmationDialogHeight,
      width: confirmationDialogWidth,
      data: {
        title: 'Delete Release Note',
        message:
          'You are about to delete the Release Note. By doing this you will lose all content added to it, would you like to proceed?',
      },
    });
    deleteDialogRef.afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result === ConfirmationDialogResult.Confirmed) {
        this.releaseNotesService.deleteReleaseNotesById(id).subscribe(() => {
          this.snackBar.open('Release Note deleted successfully.', '', {
            duration: notificationDuration,
            verticalPosition: 'top',
          });
          this.fetchReleaseNotes();
        });
      }
    });
  }
}

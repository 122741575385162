<h2 class="section-title">Tag Management</h2>
<div *ngIf="!loading">
  <h2 class="sub-heading">Uncategorised Tags</h2>
  <p>
    These tags have been added to stories that do not exist in the existing tag set. Drag tags to relevant categories or
    replace with existing tags
  </p>
  <div class="uncategorised-list">
    <div class="item tag-item" *ngFor="let tag of uncategorisedTags">
      <div class="icon-right">
        {{ tag.value }}
        <button mat-icon-button [matMenuTriggerFor]="uncategorisedMenu" [matMenuTriggerData]="{ tag: tag }">
          <i class="material-icons">more_vert</i>
        </button>
      </div>
    </div>
  </div>
  <h2 class="sub-heading">Categorised Tags</h2>
  <div class="category-list">
    <div class="item category-item" *ngFor="let category of categories">
      <div class="header icon-right">
        {{ category.name }}
        <div class="add-tag">
          <div *ngIf="!addCategorisedTag">
            <button
              mat-icon-button
              color="primary"
              (click)="toggleAddCategorisedTag(category)"
              matTooltip="Add tags to this category"
            >
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </div>
      <div class="content">
        <div id="add-tag-form" *ngIf="addCategorisedTag && category == addCategorisedTagCategory">
          <mat-form-field class="tag-chip-list">
            <mat-chip-grid #chipList>
              <mat-chip-row
                *ngFor="let tag of addedTags"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeValue(tag)"
              >
                {{ tag }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip-row>
              <input
                placeholder="New tags..."
                [formControl]="addTagControl"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
                (focusout)="focusedOut($event)"
              />
            </mat-chip-grid>
          </mat-form-field>
          <div id="tag-actions">
            <button mat-button (click)="cancel()">Cancel</button>
            <button mat-button color="primary" [disabled]="addedTags.length === 0" (click)="saveTags(category)">
              Save
            </button>
          </div>
        </div>
        <div class="tag icon-right" *ngFor="let tag of category.tags">
          <span>
            {{ tag.value }}
            <span *ngIf="tag.externalId !== null">
              <mat-icon inline matTooltip="External Tag">info</mat-icon>
            </span>
          </span>
          <button mat-icon-button [matMenuTriggerFor]="categorisedMenu" [matMenuTriggerData]="{ tag: tag }">
            <i class="material-icons">more_vert</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-spinner *ngIf="loading"></mat-spinner>
<mat-menu #uncategorisedMenu>
  <ng-template matMenuContent let-name="tag">
    <button mat-menu-item (click)="renameTag(name)">Rename</button>
    <button mat-menu-item (click)="replaceTag(name)">Replace</button>
    <button mat-menu-item (click)="deleteTag(name, true)">Delete</button>
    <button mat-menu-item (click)="moveTag(name)">Move</button>
  </ng-template>
</mat-menu>
<mat-menu #categorisedMenu>
  <ng-template matMenuContent let-name="tag">
    <button mat-menu-item (click)="renameTag(name)">Rename</button>
    <button mat-menu-item (click)="replaceTag(name)">Replace</button>
    <button mat-menu-item (click)="deleteTag(name, false)">Delete</button>
  </ng-template>
</mat-menu>

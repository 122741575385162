import { Attachment } from '../../../shared/models/attachment';
import { AttachmentType, attachmentTypeDescriptor } from '../../../shared/models/attachment-type';
import { Story } from '../../../shared/models/story';
import { ClientMessageService } from '../../../shared/services/client-message.service';
import { StoriesService } from '../../services/stories.service';
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-story-attachments',
  templateUrl: './edit-story-attachments.component.html',
  styleUrls: ['./edit-story-attachments.component.scss'],
})
export class EditStoryAttachmentsComponent {
  private attachmentFiles: Map<Attachment, File> = new Map<Attachment, File>();
  private deletedAttachments: Attachment[] = [];
  private selectedAttachment: Attachment;

  public attachmentType = AttachmentType;
  @Input()
  public attachments: Attachment[] = [];
  @ViewChild('file', { static: true }) public fileInputControl: ElementRef;
  public hasChanges: boolean;
  public isUploading = false;
  public isValid: boolean;

  attachmentTypeDescriptor = attachmentTypeDescriptor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly story: Story,
    private readonly dialogRef: MatDialogRef<EditStoryAttachmentsComponent>,
    private readonly storiesService: StoriesService,
    private readonly messageService: ClientMessageService
  ) {
    if (story && story.attachments) {
      this.attachments = [
        ...this.story.attachments.filter(this.filter).map((a) => {
          return { ...a };
        }),
      ];
    }
  }

  public addAttachment(attachmentType: AttachmentType): void {
    const attachment = new Attachment();

    attachment.id = null;
    attachment.name = null;
    attachment.attachmentType = attachmentType;
    attachment.storyId = this.story.id;

    this.attachments.push(attachment);

    this.onChange();
  }

  public addFiles(attachment: Attachment): void {
    this.selectedAttachment = attachment;

    this.fileInputControl.nativeElement.accept = this.getFilter(attachment.attachmentType);
    this.fileInputControl.nativeElement.click();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public deleteAttachment(attachment: Attachment): void {
    const attachmentIndex = this.attachments.indexOf(attachment);

    if (attachmentIndex > -1) {
      this.attachments.splice(attachmentIndex, 1);

      if (this.attachmentFiles.has(attachment)) {
        this.attachmentFiles.delete(attachment);
      }

      if (attachment.id) {
        this.deletedAttachments.push(attachment);
      }
    }

    this.onChange();
  }

  public downloadAttachment(attachment: Attachment): void {
    this.storiesService.getAttachmentUrl(attachment.storyId, attachment.id).subscribe((result) => {
      window.open(result.url);

      this.messageService.showClientInfoMessage(
        'Attachment download is ready and will be opened in a new window. \n' +
          'This link is only valid for a short time and can only be used once.'
      );
    });
  }

  public onChange() {
    this.hasChanges = true;
    this.isValid = true;

    for (const attachment of this.attachments) {
      if (!attachment.attachmentType || !attachment.name || !attachment.label) {
        this.isValid = false;
      }
    }
  }

  public onFilesAdded(): void {
    const files: File[] = this.fileInputControl.nativeElement.files;

    if (files.length === 1) {
      this.attachmentFiles.set(this.selectedAttachment, files[0]);
      this.selectedAttachment.name = files[0].name;
    }

    this.fileInputControl.nativeElement.accept = '';
    this.fileInputControl.nativeElement.value = '';

    this.onChange();
  }

  public async save(): Promise<void> {
    const promises: Promise<{}>[] = [];

    this.isUploading = true;

    // delete attachments
    for (const attachment of this.deletedAttachments) {
      promises.push(this.storiesService.deleteAttachment(this.story.id, attachment.id).toPromise());
    }

    // update attachments
    for (const attachmentNew of this.attachments.filter((a) => a.id)) {
      const attachmentOld = this.story.attachments.find((a) => a.id === attachmentNew.id);

      if (attachmentOld) {
        if (attachmentOld.label !== attachmentNew.label) {
          promises.push(
            this.storiesService.updateAttachment(this.story.id, attachmentNew.id, attachmentNew.label).toPromise()
          );
        }
      }
    }

    // upload attachments
    for (const attachmentFile of this.attachmentFiles) {
      const attachment = attachmentFile[0];
      const attachmentFile1 = attachmentFile[1];

      promises.push(
        this.storiesService
          .uploadAttachment(attachmentFile1, this.story.id, attachment.attachmentType, attachment.label)
          .toPromise()
      );
    }

    await Promise.all(promises);

    this.isUploading = false;

    this.dialogRef.close();
  }

  private filter(attachment: Attachment): boolean {
    return (
      attachmentTypeDescriptor[attachment.attachmentType].Metadata &&
      attachmentTypeDescriptor[attachment.attachmentType].Metadata.Editable
    );
  }

  private getFilter(attachmentType: AttachmentType) {
    if (attachmentType === AttachmentType.CaseStudy) {
      return '.pdf';
    } else if (attachmentType === AttachmentType.Proposal) {
      return '.pptx';
    } else if (attachmentType === AttachmentType.DeliverableScreenshot) {
      return '.png';
    } else {
      return null;
    }
  }

  get types() {
    const types = Object.values(this.attachmentType)
      .filter((value) => isNaN(Number(value)) === false)
      .filter((value) => this.attachmentTypeDescriptor[value].Metadata)
      .filter((value) => this.attachmentTypeDescriptor[value].Metadata.Editable);
    return types;
  }
}

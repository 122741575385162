import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent as MatChipInputEvent } from '@angular/material/chips';
import { User } from '../../../../shared/models/user';
import { UserService } from '../../../../shared/services/user/user.service';
import { Story } from '../../../../shared/models/story';
import { StoriesService } from '../../../services/stories.service';
import { ClientMessageService } from '../../../../shared/services/client-message.service';

@Component({
  selector: 'app-contributors-edit-mode',
  templateUrl: './contributors-edit-mode.component.html',
  styleUrls: ['./contributors-edit-mode.component.scss'],
})
export class ContributorsEditModeComponent implements OnInit {
  @Input()
  story: Story;
  @Output()
  switchToView = new EventEmitter();
  @Output()
  storyUpdated = new EventEmitter();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  contributorError: string;
  validating = false;
  newContributors: User[] = [];
  removedContributors: User[] = [];
  existingContributors: User[];

  constructor(
    private userService: UserService,
    private storiesService: StoriesService,
    private errorService: ClientMessageService
  ) {}

  ngOnInit(): void {
    this.existingContributors = [...this.story.contributors];
  }

  reset() {
    this.existingContributors = [...this.story.contributors];
    this.removedContributors = [];
    this.newContributors = [];
  }

  addContributor(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.contributorError = '';
    // Add contributor to array
    if ((value || '').trim()) {
      this.validating = true;
      if (this.existingContributors.map((c) => c.email).findIndex((c) => c === value) > -1) {
        this.contributorError = 'This person is already a contributor.';
        this.validating = false;
        return;
      }
      if (this.story.owner.email === value) {
        this.contributorError = 'This person is the story owner.';
        this.validating = false;
        return;
      }
      this.userService.getUserByEmail(value).subscribe(
        (user) => {
          this.validating = false;
          if (this.existingContributors.map((c) => c.id).findIndex((c) => c === user.id) > -1) {
            this.contributorError = 'This person is already a contributor.';
          } else {
            this.newContributors.push(user);
            input.value = '';
          }
        },
        (error) => {
          this.validating = false;
          this.contributorError =
            'We could not recognise this email address. Please contact support if this is someone at our workplace.';
        }
      );
    }
  }

  removeContributor(contributor: User): void {
    const newContributorIndex = this.newContributors.indexOf(contributor);

    if (newContributorIndex >= 0) {
      this.newContributors.splice(newContributorIndex, 1);
      return;
    }

    const existingContributorIndex = this.existingContributors.indexOf(contributor);
    if (existingContributorIndex >= 0) {
      this.existingContributors.splice(existingContributorIndex, 1);
      this.removedContributors.push(contributor);
    }
  }

  updateContributors() {
    const allContributors = this.existingContributors.concat(this.newContributors);
    this.storiesService
      .updateContributors(
        this.story.id,
        this.newContributors.map((c) => c.email),
        this.removedContributors.map((c) => c.email)
      )
      .subscribe(() => {
        this.existingContributors = Object.assign([], allContributors);
        this.story.contributors = Object.assign([], allContributors);
        this.newContributors = [];
        this.removedContributors = [];
        this.switchToView.emit();
        this.errorService.showClientInfoMessage('The contributors have been successfully updated');
      });
  }

  cancelEditing() {
    this.reset();
    this.switchToView.emit();
  }
}

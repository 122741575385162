<mat-form-field class="autocomplete-item">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let value of selectedOptions"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="onChange(value)"
    >
      {{ value.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="{{ title }}"
      #queryInput
      [formControl]="valueCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (keydown.Space)="onOptionKeyDown($event)"
      (keydown.Tab)="clearQueryInput(true)"
      (blur)="clearQueryInput(false)"
      #autoTrigger="matAutocompleteTrigger"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option
      [disabled]="value.isLegacy ? 'disabled' : 'false'"
      *ngFor="let value of filteredTags | async"
      (blur)="OnAutocompleteBlur()"
      [value]="selectedTags"
    >
      <div
        (click)="onOptionWrapperClicked($event, value)"
        (mouseenter)="onOptionsMouseEnter()"
        (mouseleave)="onOptionsMouseLeave()"
      >
        <mat-checkbox
          [disabled]="value.isLegacy ? 'disabled' : 'false'"
          [checked]="value.selected"
          (change)="onChange(value)"
          (click)="$event.stopPropagation()"
        >
          {{ value.name }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

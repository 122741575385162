import { MsalService } from '@azure/msal-angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { ConfigurationService } from '../../services/configuration/configuration.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  title = 'StoryHub';
  isAdmin: boolean;
  isAuthenticated: boolean;
  authenticationDetails: string;
  hangfireUrl: string;

  constructor(private router: Router, private configService: ConfigurationService, private userService: UserService, private msalService: MsalService) {}

  ngOnInit(): void {
    const account = this.msalService.instance.getActiveAccount();
    this.isAuthenticated = !!account;
    this.hangfireUrl = this.configService.hangfireUrl;

    if (this.isAuthenticated) {
      this.authenticationDetails = 'Logged in as: ' + account.username + ' (' + account.name + ')';
      this.userService.getUser().subscribe((user) => (this.isAdmin = user.isAdmin));
    }
  }

  logout() {
    this.msalService.logout();
  }

  navigateHome() {
    this.router.navigate(['']);
  }
}

import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {

  private msalTelemetryInitialzerAdded = false;
  private readonly appInsights: ApplicationInsights;

  constructor(
    configuration: ConfigurationService,
    router: Router,
    private injector: Injector) {

    if (configuration.applicationInsightsKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: configuration.applicationInsightsKey,
        }
      });

      this.appInsights.loadAppInsights();

      router.events
        .pipe(filter((event) => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(`${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
          }
        });
    }
  }

  public logPageView(name?: string, uri?: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.ensureMsalTelemetryInitializer();
      this.appInsights.trackPageView({
        name,
        uri,
        properties,
      });
    }
  }

  public logEvent(name: string, properties?: { [key: string]: string }) {
    if (this.appInsights) {
      this.ensureMsalTelemetryInitializer();
      this.appInsights.trackEvent({
        name,
        properties,
      });
    }
  }

  public logError(exception: Error, properties?: { [key: string]: string }) {
    if (this.appInsights) {
      this.ensureMsalTelemetryInitializer();
      this.appInsights.trackException({
        exception,
        properties,
      });
    }
  }

  private ensureMsalTelemetryInitializer(): void {
    if (!this.msalTelemetryInitialzerAdded) {
      this.msalTelemetryInitialzerAdded = true;

      const msalService = this.injector.get(MsalService);
      this.appInsights.addTelemetryInitializer((telemetryItem) => {
        const account = msalService.instance.getActiveAccount();
        if (account && account.localAccountId) {
          telemetryItem.data.oid = account.localAccountId;
        }
      });
    }
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }
}

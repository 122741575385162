<mat-card appearance="outlined" class="metaCard" [ngClass]="{ 'readonly-story-meta': isReadOnly }">
  <mat-card-content>
    <app-meta-section header="Phase">
      <div *ngIf="!phaseEditMode" fxLayout="column" fxLayoutAlign="start stretch">
        <div class="documentStatus">
          <span>{{ storyPhaseDescriptor[story.phase].Label }}</span>
        </div>
        <div *ngIf="story.phase >= StoryPhase.Deliver" class="saleClosedDate">
          <span>Sale close date:</span> <span>{{ story.saleClosedDate | date }}</span>
        </div>
        <div *ngIf="story.phase >= StoryPhase.Deliver" class="deliveryStartDate">
          <span>Delivery start date:</span> <span>{{ story.deliveryStartDate | date }}</span>
        </div>
        <div *ngIf="story.phase >= StoryPhase.Measure" class="deliveryCompletedDate">
          <span>Delivery completed date:</span> <span>{{ story.deliveryCompletedDate | date }}</span>
        </div>
        <div *ngIf="story.goneToProductionDate !== null" class="goneToProductionDate">
          <span>Gone to production date:</span> <span>{{ story.goneToProductionDate | date }}</span>
        </div>

        <div class="updateCancelButtons" *ngIf="!isReadOnly">
          <button mat-button *ngIf="currentUserIsStoryOwner" color="primary" (click)="phaseEditMode = true">
            CHANGE
          </button>
        </div>
      </div>
      <app-phase-edit-mode
        *ngIf="phaseEditMode"
        [story]="story"
        (storyUpdated)="refreshStory()"
        (switchToView)="phaseEditMode = false"
      >
      </app-phase-edit-mode>
    </app-meta-section>

    <app-meta-section header="Story Status">
      <div *ngIf="!stateEditMode">
        <div [ngClass]="{ highlight: story.state === StoryState.Draft }">
          {{ storyStateDescriptor[story.state].Label }}
        </div>
        <div class="updateCancelButtons" *ngIf="!isReadOnly">
          <button mat-button *ngIf="currentUserIsStoryOwner" color="primary" (click)="stateEditMode = true">
            CHANGE
          </button>
        </div>
      </div>
      <app-state-edit-mode
        *ngIf="stateEditMode"
        [story]="story"
        (switchToView)="stateEditMode = false"
        (storyUpdated)="refreshStory()"
      >
      </app-state-edit-mode>
    </app-meta-section>

    <app-meta-section *ngIf="story.state == StoryState.Published" header="Visibility">
      <div *ngIf="!visibilityEditMode">
        <div>
          {{ storyVisibilityDescriptor[story.visibility].Label }}
          <mat-icon matTooltip="{{ visibilityInfoMessages(story.visibility) }}">info</mat-icon>
          <span class="sharing-conditions">{{ message }}</span>
        </div>
        <div class="updateCancelButtons" *ngIf="!isReadOnly">
          <button mat-button *ngIf="currentUserIsStoryOwner" color="primary" (click)="visibilityEditMode = true">
            CHANGE
          </button>
        </div>
      </div>
      <app-visibility-edit-mode
        *ngIf="visibilityEditMode"
        [story]="story"
        (switchToView)="visibilityEditMode = false"
        (storyUpdated)="refreshStory()"
      ></app-visibility-edit-mode>
    </app-meta-section>

    <app-meta-section header="Sharing Conditions">
      <div *ngIf="!sharingConditionsEditMode">
        <div>
          <strong *ngIf="story.sharingConditions; else noSharingCondition"> Sharing Conditions Specified</strong>
          <ng-template #noSharingCondition>No Sharing Conditions</ng-template>
        </div>
        <div class="updateCancelButtons" *ngIf="!isReadOnly">
          <button mat-button *ngIf="currentUserIsStoryOwner" color="primary" (click)="sharingConditionsEditMode = true">
            CHANGE
          </button>
        </div>
      </div>
      <app-sharing-conditions-edit-mode
        *ngIf="sharingConditionsEditMode"
        [story]="story"
        (switchToView)="sharingConditionsEditMode = false"
        (storyUpdated)="refreshStory()"
      ></app-sharing-conditions-edit-mode>
    </app-meta-section>

    <app-meta-section header="Owner">
      <app-owner-view-mode
        *ngIf="!ownerEditMode"
        [story]="story"
        [isReadOnly]="isReadOnly"
        [currentUserIsStoryOwner]="currentUserIsStoryOwner"
        (edit)="manageOwner()"
      >
      </app-owner-view-mode>
      <app-owner-edit-mode
        *ngIf="ownerEditMode"
        [isReadOnly]="isReadOnly"
        [story]="story"
        (finishedEditing)="cancelOwner()"
        [existingContributors]="story.contributors"
      >
      </app-owner-edit-mode>
    </app-meta-section>

    <app-meta-section header="Contributors">
      <app-contributors-edit-mode
        *ngIf="contributorsEditMode"
        [story]="story"
        (switchToView)="contributorsEditMode = false"
      >
      </app-contributors-edit-mode>
      <app-contributors-view-mode
        *ngIf="!contributorsEditMode"
        [contributors]="story.contributors"
        (switchToEdit)="contributorsEditMode = true"
        [isReadOnly]="isReadOnly"
      >
      </app-contributors-view-mode>
    </app-meta-section>
    <app-meta-section header="Story Tags" [errorMessage]="tagsErrorMessages" [isReadOnly]="isReadOnly">
      <app-tags-view-mode
        *ngIf="!tagsEditMode"
        [tags]="tags"
        (switchToEdit)="tagsEditMode = true"
        [isReadOnly]="isReadOnly"
      >
      </app-tags-view-mode>
      <app-tags-edit-mode
        *ngIf="tagsEditMode"
        [story]="story"
        (switchToView)="tagsEditMode = false"
        (storyUpdated)="refreshStory()"
      >
      </app-tags-edit-mode>
    </app-meta-section>
    <app-meta-section header="Case study nomination">
      <app-case-study-nomination-view-mode
        *ngIf="!caseStudyNominationEditMode"
        [story]="story"
        (switchToEdit)="caseStudyNominationEditMode = true"
        [isReadOnly]="isReadOnly"
        [currentUserIsStoryOwner]="currentUserIsStoryOwner"
      >
      </app-case-study-nomination-view-mode>
      <app-case-study-nomination-edit-mode
        *ngIf="caseStudyNominationEditMode"
        [story]="story"
        (switchToView)="caseStudyNominationEditMode = false"
        (storyUpdated)="refreshStory()"
      >
      </app-case-study-nomination-edit-mode>
    </app-meta-section>
  </mat-card-content>
</mat-card>

<a class="story-display-item-wrapper" [href]="'/view-story/' + this.story.id" (click)="storyClick($event)">
  <mat-card appearance="outlined">
    <div class="image-container">
      <img *ngIf="!story.hasFeaturedImage && !imageIsLoading" src="../assets/default-story-photo.PNG" />
      <img *ngIf="story.hasFeaturedImage" [hidden]="imageIsLoading" [src]="featuredImageUrl" (load)="imageLoaded()" />
      <mat-spinner diameter="25" *ngIf="imageIsLoading"></mat-spinner>
      <app-story-visibility-badge
        [storyVisibility]="story.visibility"
        [storyState]="story.state"
      ></app-story-visibility-badge>
    </div>
    <mat-card-content>
      <mat-card-subtitle>{{ storyClient }}</mat-card-subtitle>
      <mat-card-title>{{ storyTitle }}</mat-card-title>
      <p [clamp]="3" *ngIf="storySummary" [innerHTML]="md.render(storySummary)"></p>
    </mat-card-content>
    <mat-card-footer>
      <p *ngIf="story.deliveredBy.length > 0 && storyDeliveredBy"><span>Delivered by:</span> {{ storyDeliveredBy }}</p>
      <p><span>Last updated:</span> {{ lastEdited | date: 'dd/MM/yyyy' }}</p>
    </mat-card-footer>
  </mat-card>
</a>

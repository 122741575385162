import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './shared/services/configuration/configuration.service';
import { environment } from '../environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { LandingUrlKey } from './shared/components/login/login.component';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LoggingService } from './shared/services/logging/logging.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isIFrame = false;
  public isAuthenticated = false;
  private readonly _destroying$ = new Subject<void>();
  
  constructor(
    private configurationService: ConfigurationService,
    private msalService: MsalService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: LoggingService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    // this.msalService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     this.logger.logError(authError, {
    //       message: 'Error authenticating',
    //       response: JSON.stringify(response),
    //     });
    //     return;
    //   }
    // });
  }

  ngOnInit() {
    // User needs to be authenticated to access StoryHub
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (result.error && result.error.message.indexOf('AADSTS50105')) {
          this.router.navigate(['no-access']);
        }
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.router.navigate(['no-access']);
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.isAuthenticated = true;
      });

    if (environment.production) {
      this.tokenRefreshCounter();
    }

    this.updateIsIFrame();
    this.updateTitle();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  tokenRefreshCounter() {
    setInterval(() => {
      this.msalService.acquireTokenSilent({
        scopes: [this.configurationService.azureConfig.clientId],
        forceRefresh: true,
      });
    }, this.configurationService.tokenRefreshTimeMinutes * 60 * 1000); // unit is in milliseconds here
  }

  updateTitle() {
    const appTitle = 'StoryHub';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  updateIsIFrame() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['isIFrame']) {
            return child.snapshot.data['isIFrame'];
          }
          return false;
        })
      )
      .subscribe((isIFrame: boolean) => {
        this.isIFrame = isIFrame;
      });
  }
}

<mat-card appearance="outlined" class="item-card" (click)="action.emit()">
  <img *ngIf="!loading" mat-card-image [src]="image" />
  <div class="spinner" *ngIf="loading">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
  <mat-card-content class="item-content">
    <app-icon [icon]="icon" [title]="iconTitle"></app-icon>
    <div class="item-titles">
      <div class="long-text">
        <strong>{{ title }}</strong>
      </div>
      <div class="long-text mat-caption">{{ subtitle }}</div>
    </div>
  </mat-card-content>
</mat-card>

// This code allows using the ngx-quill editor within angular material.
// The code is taken from: https://github.com/KillerCodeMonkey/ngx-quill-example

import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { MatFormFieldControl as MatFormFieldControl } from '@angular/material/form-field';
import { MatQuillBaseDirective } from './mat-quill-base';

// Increasing integer for generating unique ids for mat-quill components.
let nextUniqueId = 0;

@Component({
  selector: 'app-mat-quill',
  exportAs: 'matQuill',
  template: `
    <ng-container *ngIf="toolbarPosition !== 'top'">
      <div quill-editor-element *ngIf="!preserve"></div>
      <pre quill-editor-element *ngIf="preserve"></pre>
    </ng-container>
    <ng-content select="[quill-editor-toolbar]"></ng-content>
    <ng-container *ngIf="toolbarPosition === 'top'">
      <div quill-editor-element *ngIf="!preserve"></div>
      <pre quill-editor-element *ngIf="preserve"></pre>
    </ng-container>
    <ng-content></ng-content>
  `,
  inputs: ['disabled'],
  providers: [{ provide: MatFormFieldControl, useExisting: MatQuillComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatQuillComponent extends MatQuillBaseDirective {
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;

  controlType = 'app-mat-quill';
  @HostBinding() id = `app-mat-quill-${nextUniqueId++}`;
}

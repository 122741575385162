import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';

const MatModules = [
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatSlideToggleModule,
  MatChipsModule,
  MatTableModule,
  MatSelectModule,
  MatTabsModule,
  MatDialogModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatOptionModule,
  MatRadioModule,
  MatListModule,
  MatSidenavModule,
  MatTooltipModule,
  MatFormFieldModule,
];

@NgModule({
  imports: MatModules,
  exports: MatModules,
})
export class MaterialModule {}

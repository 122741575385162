import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { storyTitleMaxCharacterLimit } from '../../../shared/constants';
import { StoriesService } from '../../services/stories.service';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user/user.service';
import { User } from '../../../shared/models/user';
import { CreateStory, CreateStoryResponse } from '../../../shared/models/create-story';
import { ValueNumberValidator } from '../../../shared/validator-functions';

@Component({
  selector: 'app-new-story',
  templateUrl: './new-story-dialog.component.html',
  styleUrls: ['./new-story-dialog.component.scss'],
})
export class NewStoryDialogComponent implements OnInit {
  storyTitleCharacterLimit = storyTitleMaxCharacterLimit;
  newStoryForm = new UntypedFormGroup({
    title: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.storyTitleCharacterLimit),
    ]),
    clientName: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.storyTitleCharacterLimit),
    ]),
    initialValue: new UntypedFormControl(),
    owner: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private dialogRef: MatDialogRef<NewStoryDialogComponent>,
    private router: Router,
    private storiesService: StoriesService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe((user: User) => {
      this.newStoryForm.controls['owner'].setValue(user.fullName);
      this.newStoryForm.controls['owner'].disable();
    });
  }
  createStory(): void {
    const formValue = this.newStoryForm.value as CreateStory;
    this.storiesService.createStory(formValue).subscribe((created: CreateStoryResponse) => {
      this.dialogRef.close();
      this.router.navigate(['/story', created.id]);
    });
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}

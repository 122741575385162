import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';

import {
  ConfigurationService,
  ConfigurationServiceHttpClientFactory,
} from './shared/services/configuration/configuration.service';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoryModule } from './story/story.module';
import { TagManagementModule } from './tag-management/tag-management.module';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LoggingService } from './shared/services/logging/logging.service';
import { GlobalHttpInterceptor } from './shared/global-http.interceptor';
import { ClientMessageService } from './shared/services/client-message.service';
import { SkipInterceptor } from './shared/skip-interceptor';
import { NewStoryDialogComponent } from './story/components/new-story/new-story-dialog.component';
import { UserService } from './shared/services/user/user.service';
import { EditStoryTitleDialogComponent } from './story/components/edit-story-title/edit-story-title-dialog.component';
import { EditStoryPartDialogComponent } from './story/components/edit-story-part/edit-story-part-dialog.component';
import { DeleteStoryDialogComponent } from './story/components/delete-story-dialog/delete-story-dialog.component';
import { UpdateOwnerDialogComponent } from './story/components/update-owner/update-owner-dialog.component';
import { ChangeRevisionDialogComponent } from './story/components/change-revision/change-revision-dialog.component';
import { TagService } from './shared/services/tag/tag.service';
import { StoryTagComponent } from './story/components/story-tag/story-tag.component';
import { ViewStoryReadonlyDialogComponent } from './story/components/view-story-readonly-dialog/view-story-readonly-dialog.component';
import { FeaturedImageService } from './shared/services/featured-images/featured-image.service';
import { DeleteAttachmentDialogComponent } from './story/components/delete-attachment-dialog/delete-attachment-dialog.component';
import { ShareStoryDialogComponent } from './story/components/share-story-dialog/share-story-dialog.component';
import { MOMENT_DATE_FORMATS } from './shared/date-formats-momentdateadapter';
import { AboutComponent } from './about/about.component';
import {
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuard,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG
} from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType, LogLevel } from "@azure/msal-browser";
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { ViewLikesDialogComponent } from './story/components/view-likes/view-likes-dialog.component';
import { TagRenameComponent } from './tag-management/components/tag-rename/tag-rename.component';
import { TagReplaceDeleteComponent } from './tag-management/components/tag-replace-delete/tag-replace-delete.component';
import { TagMoveComponent } from './tag-management/components/tag-move/tag-move.component';
import { RouterExtService } from './shared/services/router-ext-service/router-ext.service';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExportModule } from './export/export.module';
import { AboutModule } from './about/about.module';
import { NewOrEditReleaseNoteComponent } from './about/components/new-or-edit-release-note-dialog/new-or-edit-release-note-dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { AddReleaseNoteComponent } from './about/components/add-release-note/add-release-note.component';

export function init(config: ConfigurationService) {
  return () => config.load().toPromise();
}
export function MSALInstanceFactory(config: ConfigurationService, loggingService: LoggingService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.azureConfig.clientId,
      authority: 'https://login.microsoftonline.com/' + config.azureConfig.tenant,
      redirectUri: config.azureConfig.redirectUri,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: config.azureConfig.cacheLocation
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback:(level: LogLevel, message: string) => {
          console.log(message);
          loggingService.logError(new Error(message), { source: 'MSAL.js' });
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(config: ConfigurationService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(config.apiConfig.uri, config.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(config: ConfigurationService): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...config.apiConfig.scopes]
    },
    loginFailedRoute: '/no-access'
  };
}

@NgModule({
    declarations: [AppComponent, AboutComponent],
    imports: [
        CommonModule,      
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        StoryModule,
        AboutModule,        
        TagManagementModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'STORYHUB-XSRF-TOKEN',
            headerName: 'STORYHUB-XSRF-TOKEN',
        }),
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatSnackBarModule,
        MatDialogModule,
        MatCardModule,
        MatSidenavModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressBarModule,
        FlexLayoutModule,
        MsalModule,
        ExportModule,
        MatInputModule,
        MatFormFieldModule,
        RouterModule        
    ],
    providers: [
        MsalService,
        MsalGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: init,
            deps: [ConfigurationService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ConfigurationService, LoggingService],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [ConfigurationService],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigurationService],
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        // Use Moment.js DateAdapter and app-wide date format configuration:
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MOMENT_DATE_FORMATS,
        },
        // Australian locale:
        {
            provide: LOCALE_ID,
            useValue: 'en-AU',
        },
        // Services:
        ClientMessageService,
        ConfigurationServiceHttpClientFactory,
        ConfigurationService,
        FeaturedImageService,
        LoggingService,
        RouterExtService,
        SkipInterceptor,
        UserService,
        TagService,
        DatePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}

import { Attachment } from '../../../shared/models/attachment';
import { attachmentTypeDescriptor } from '../../../shared/models/attachment-type';
import { Story } from '../../../shared/models/story';
import { ClientMessageService } from '../../../shared/services/client-message.service';
import { StoriesService } from '../../services/stories.service';
import { EditStoryAttachmentsComponent } from '../edit-story-attachments/edit-story-attachments.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-story-attachments',
  templateUrl: './view-story-attachments.component.html',
  styleUrls: ['./view-story-attachments.component.scss'],
})
export class ViewStoryAttachmentsComponent implements OnInit {
  private readonly dialogWidth = '750px';

  @Output() public afterDialogClosed = new EventEmitter<string>();
  @Input() public story: Story;
  @Input() canEdit: boolean;

  attachmentTypeDescriptor = attachmentTypeDescriptor;

  constructor(
    private readonly storiesService: StoriesService,
    private readonly messageService: ClientMessageService,
    private readonly dialog: MatDialog
  ) {}

  public get attachments(): Attachment[] {
    if (this.story) {
      return (
        this.story.attachments
          .filter((a) => attachmentTypeDescriptor[a.attachmentType].Metadata)
          .sort(
            (a, b) =>
              attachmentTypeDescriptor[a.attachmentType].Metadata.Order -
              attachmentTypeDescriptor[b.attachmentType].Metadata.Order
          ) || []
      );
    } else {
      return [];
    }
  }

  public downloadAttachment(attachment: Attachment): void {
    this.storiesService.getAttachmentUrl(attachment.storyId, attachment.id).subscribe((result) => {
      window.open(result.url);

      this.messageService.showClientInfoMessage(
        'Attachment download is ready and will be opened in a new window. \n' +
          'This link is only valid for a short time and can only be used once.'
      );
    });
  }

  public async edit(): Promise<void> {
    if (!this.canEdit) return;
    const dialogRef = this.dialog.open(EditStoryAttachmentsComponent, {
      width: this.dialogWidth,
      data: this.story,
    });

    await dialogRef.afterClosed().toPromise();

    this.afterDialogClosed.emit(this.story.id);
  }

  public ngOnInit(): void {}
}

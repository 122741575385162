<div id="nav-container" class="nav-bar-container" *ngIf="!isIFrame && isAuthenticated" [style.height]="isIFrame ? '0' : 'inherit'">
  <app-nav-bar class="nav-bar"></app-nav-bar>
</div>

<div class="low-margin" *ngIf="!isIFrame">
  <router-outlet name="fullwidth"></router-outlet>
</div>
<div class="outlet-container" [style.margin-top]="isIFrame ? '0' : '40px'">
  <router-outlet></router-outlet>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-story-dialog',
  templateUrl: './delete-story-dialog.component.html',
  styleUrls: ['./delete-story-dialog.component.scss'],
})
export class DeleteStoryDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DeleteStoryDialogComponent>) {}

  ngOnInit() {}

  onCancel(): void {
    this.dialogRef.close('cancel');
  }

  onDelete(): void {
    this.dialogRef.close('delete');
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { interceptorSkip401RedirectHeader, interceptorSkip409ConflictHeader } from 'src/app/shared/constants';
import { ReleaseNote } from 'src/app/shared/models/release-note';
import { ConfigurationService } from 'src/app/shared/services/configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  baseApiUrl: string;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private releaseNoteDeletedSource = new Subject();
  releaseNoteDeleted$ = this.releaseNoteDeletedSource.asObservable();

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.baseApiUrl = this.config.apiUrl + '/releasenotes';
  }

  deleteReleaseNote() {
    this.releaseNoteDeletedSource.next(null);
  }

  createReleaseNote(releaseNote: ReleaseNote): Observable<string | {}> {
    return this.http.post(this.baseApiUrl, releaseNote, { headers: this.headers });
  }

  updateReleaseNote(releaseNote: ReleaseNote): Observable<any> {
    return this.http.put(`${this.baseApiUrl}/${releaseNote.id}`, releaseNote, { headers: this.headers });
  }

  getReleaseNotes(): Observable<ReleaseNote[]> {
    return this.http.get<ReleaseNote[]>(this.baseApiUrl, { headers: this.headers });
  }

  deleteReleaseNotesById(id: string): Observable<any> {
    return this.http.delete(this.baseApiUrl + `/${id}`, { headers: this.headers });
  }
}

<div *ngIf="isLoadingAllTags">
  <mat-spinner diameter="50"></mat-spinner>
</div>
<div class="category-container" *ngIf="!isLoadingAllTags">
  <app-story-tag-autocomplete
    class="category-item"
    *ngFor="let category of allCategories"
    (onSelectionChanged)="selectionChanged($event)"
    [allTags]="category.tags"
    [title]="category.name"
    [(selectedTags)]="category.selectedTags"
  >
  </app-story-tag-autocomplete>
</div>
<mat-form-field class="tag-chip-list" *ngIf="!isLoadingAllTags">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let tag of uncategorisedTags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="removeValue(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="New Tags..."
      #tagInput
      [formControl]="tagCtrl"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      (focusout)="focusedOut($event)"
    />
  </mat-chip-grid>
</mat-form-field>

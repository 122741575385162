import { Injectable } from '@angular/core';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ClientMessageService {
  constructor(private snackBar: MatSnackBar) {}

  showClientErrorMessage(message: string) {
    this.snackBar.open(
      message + (message.endsWith('.') ? ' ' : '. ') + 'Please try again or contact support if the problem persists.',
      'Dismiss',
      {
        verticalPosition: 'top',
        panelClass: 'error',
      }
    );
  }

  showClientErrorMessageCustom(message: string, popUpDismissMessage: string, duration: number) {
    this.snackBar.open(message, popUpDismissMessage, {
      duration: duration,
      verticalPosition: 'top',
      panelClass: 'error',
    });
  }

  showClientInfoMessage(message: string, duration = 3000) {
    this.snackBar.open(message, 'Dismiss', {
      duration: duration,
      verticalPosition: 'top',
      panelClass: 'information',
    });
  }

  showClientInfoMessageCustom(message: string, popUpDismissMessage: string, duration: number) {
    this.snackBar.open(message, popUpDismissMessage, {
      duration: duration,
      verticalPosition: 'top',
      panelClass: 'information',
    });
  }
}

<mat-card appearance="outlined">
  <mat-card-title>
    <div class="heading">
      <h4>Refine</h4>
      <button mat-button color="primary" (click)="reset()">Reset</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div [hidden]="!isLoading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <mat-expansion-panel
      [hidden]="isLoading"
      *ngFor="let group of allRegularFilters"
      [expanded]="expandedFilters.indexOf(group.label) > -1"
      class="metaPanel mat-elevation-z0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5>{{ group.label }}</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <mat-checkbox *ngFor="let field of group.fields" [(ngModel)]="field.checked" (change)="itemChanged()">
          <span>{{ field.label }}</span>
        </mat-checkbox>
      </div>
    </mat-expansion-panel>

    <app-story-clients-selection
      [clients]="storySearchClients"
      [selectedClients]="selectedClients"
      (clientSelectionChanged)="onClientSelectionChanged($event)"
    ></app-story-clients-selection>
  </mat-card-content>
</mat-card>

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatAutocomplete as MatAutocomplete, MatAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatOption as MatOption } from '@angular/material/core';
import { TagWithCategory } from 'src/app/shared/models/tag-with-category';
import { EventManager } from '@angular/platform-browser';

export class TagViewModel {
  constructor(public name: string, public selected?: boolean, public isLegacy?: boolean) {
    if (selected === undefined) selected = false;
    if (isLegacy === undefined) isLegacy = false;
  }
}

@Component({
  selector: 'app-story-tag-autocomplete',
  templateUrl: './story-tag-autocomplete.component.html',
  styleUrls: ['./story-tag-autocomplete.component.scss'],
})
export class StoryTagAutocompleteComponent implements OnInit {
  // MatChip Config
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [COMMA];

  valueCtrl = new UntypedFormControl();
  filteredTags: Observable<TagViewModel[]>;
  selectedOptions: TagViewModel[] = [];
  allTagOptions: TagViewModel[] = [];
  lastFilter: string = '';
  activeOption: MatOption;

  @Input() title: string;
  @Input() allTags: TagWithCategory[];
  @Input() selectedTags: string[];
  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('queryInput') queryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoTrigger', { read: MatAutocompleteTrigger })
  autoTrigger: MatAutocompleteTrigger;

  constructor() {}

  ngOnInit(): void {
    this.filteredTags = this.valueCtrl.valueChanges.pipe(
      startWith<string | TagViewModel[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this._filter(filter))
    );

    this.allTagOptions = this._mapValuesToViewModel(this.allTags, this.selectedTags);
    this.allTagOptions.forEach((t) => {
      if (t.selected) this.selectedOptions.push(t);
    });
    this.valueCtrl.setValue(null); //use this to apply changes instantly
  }

  onChange(tag: TagViewModel): void {
    tag.selected = !tag.selected;
    tag.selected ? this._add(tag) : this._remove(tag);
    this.onSelectionChanged.emit(this.selectedOptions);
  }

  onOptionWrapperClicked(event: Event, tag: TagViewModel): void {
    event.stopPropagation();
    this.onChange(tag);
    this.isOnOptionsList = true;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const option = event.option.viewValue;
    if (!this._getSelectedOption(option)) this._selectOption(option);

    this.clearQueryInput(true);
  }

  onOptionKeyDown(event: KeyboardEvent): void {
    const isOptionActive = this.autoTrigger.activeOption;
    if (isOptionActive) {
      this._selectOption(this.autoTrigger.activeOption.viewValue);
      event.preventDefault();
    }
  }

  public clearQueryInput(force: boolean): void {
    if (force || !this.isOnOptionsList) {
      this.queryInput.nativeElement.value = '';
      this.valueCtrl.setValue('');
      this._filter('');
    }
  }

  public isOnOptionsList: Boolean = false;
  public onOptionsMouseEnter(): void {
    this.isOnOptionsList = true;
  }
  public onOptionsMouseLeave(): void {
    this.isOnOptionsList = false;
  }

  public OnAutocompleteBlur(): void {
    this.clearQueryInput(false);
  }

  private _selectOption(option: string): void {
    const tagSelected = this._getSelectedOption(option);
    if (tagSelected) {
      this.onChange(tagSelected);
    } else {
      let tagToAdd = this.allTagOptions.find((x) => x.name === option);
      tagToAdd.selected = false;
      this.onChange(tagToAdd);
    }
  }

  private _filter(filter: string): TagViewModel[] {
    this.lastFilter = filter;
    const filterValue = filter.toLowerCase();

    if (filter) {
      return this.allTagOptions.filter((option) => {
        return option.name.toLowerCase().indexOf(filterValue) >= 0;
      });
    } else {
      return this.allTagOptions.slice();
    }
  }

  private _add(tag: TagViewModel): void {
    this.selectedOptions.push(tag);

    // Update the parent data model
    const parentTag = this.selectedTags.find((t) => t === tag.name);
    if (!parentTag) this.selectedTags.push(tag.name);
  }

  private _remove(tag: TagViewModel): void {
    const indexTag = this.selectedOptions.findIndex((v) => v.name === tag.name);
    const indexVal = this.selectedTags.findIndex((v) => v === tag.name);

    if (indexTag >= 0) {
      this.selectedOptions.splice(indexTag, 1);
    }

    if (indexVal >= 0) {
      this.selectedTags.splice(indexVal, 1);
    }
  }

  private _mapValuesToViewModel(allTags: TagWithCategory[], selectedTags: string[]): TagViewModel[] {
    return allTags.map((t) => {
      const name = t.value.trim();
      const isSelected = selectedTags.includes(t.value);
      const newTag = new TagViewModel(name, isSelected, t.isLegacy);
      return newTag;
    });
  }

  private _getSelectedOption(option: string): TagViewModel {
    return this.selectedOptions.find((x) => x.name === option);
  }
}

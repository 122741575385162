import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Story } from 'src/app/shared/models/story';
import { StoryPart, storyPartDescriptor } from '../../../shared/models/story-part';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import {
  EditStoryPartDialogComponent,
  UpdateStoryPartDialogData,
} from '../edit-story-part/edit-story-part-dialog.component';
import {
  ChangeRevisionDialogComponent,
  ChangeRevisionDialogData,
} from '../change-revision/change-revision-dialog.component';
import { Revision } from 'src/app/shared/models/revision';
import { StoriesService } from '../../services/stories.service';
import markdownIt from 'markdown-it';

const editStoryPartDialogHeight = '550px';
const editStoryPartDialogWidth = '750px';

@Component({
  selector: 'app-view-story-part',
  templateUrl: './view-story-part.component.html',
})
export class ViewStoryPartComponent {
  @Input() story: Story;
  @Input() storyPart: StoryPart;
  @Input() isReadOnly: boolean;
  @Input() canEdit: boolean;
  @Output() afterDialogClosed = new EventEmitter<string>();

  readonly storyPartDescriptor = storyPartDescriptor;
  readonly md = new markdownIt();

  constructor(private storiesService: StoriesService, private dialog: MatDialog) {}

  editStoryPart(storyPart: StoryPart): void {
    const editStoryPartDialogRef = this.dialog.open(EditStoryPartDialogComponent, {
      width: editStoryPartDialogWidth,
      height: editStoryPartDialogHeight,
      data: {
        id: this.story.id,
        storyPart: storyPart,
        placeholder: storyPartDescriptor[storyPart].Description,
        value: this.story.getStoryPartValue(storyPart),
        title: storyPartDescriptor[storyPart].Label,
      } as UpdateStoryPartDialogData,
    });
    editStoryPartDialogRef.afterClosed().subscribe(() => {
      this.afterDialogClosed.emit(this.story.id);
    });
  }

  hasRevisions(storyPart: StoryPart): boolean {
    return (
      this.story.revisionsCount &&
      this.story.revisionsCount.find((x) => x.storyPart === storyPart && x.count > 1) !== undefined
    );
  }

  revisionCount(storyPart: StoryPart): number {
    const r = this.story.revisionsCount.find((x) => x.storyPart === storyPart && x.count > 0);
    return r.count;
  }

  reviewRevisions(storyPart: StoryPart): void {
    this.storiesService
      .getStoryPartRevisions(this.story.id, storyPart)
      .subscribe((revisions) => this.openRevisionDialog(storyPart, revisions));
  }

  openRevisionDialog(storyPart: StoryPart, revisions: Revision[]): void {
    const title = this.storyPartDescriptor[storyPart].Label;

    const dialogRef = this.dialog.open(ChangeRevisionDialogComponent, {
      height: '548px',
      width: '876px',
      data: {
        title: `${title} revisions`,
        revisions: revisions,
      } as ChangeRevisionDialogData,
    });

    dialogRef.afterClosed().subscribe((r: Revision) => {
      if (r) {
        this.afterDialogClosed.emit(r.storyId);
      }
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Story } from '../../../../shared/models/story';
import { User } from '../../../../shared/models/user';
import { MatAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { StoriesService } from '../../../services/stories.service';
import { UpdateOwnerDialogComponent } from '../../update-owner/update-owner-dialog.component';
import { UpdateOwner } from '../../../../shared/models/update-owner';
import { MatDialog as MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-owner-edit-mode',
  templateUrl: './owner-edit-mode.component.html',
  styleUrls: ['./owner-edit-mode.component.scss'],
})
export class OwnerEditModeComponent implements OnInit, OnChanges {
  @Input()
  story: Story;
  @Input()
  existingContributors: User[];
  @Input()
  isReadOnly: boolean;
  @Output()
  finishedEditing = new EventEmitter();

  ownerControl = new UntypedFormControl('', [Validators.required]);
  newSelectedOwner: User;

  readonly updateOwnerDialogWidth = '600px';
  readonly updateOwnerDialogHeight = '300px';

  constructor(private storiesService: StoriesService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.ownerControl.setValue(this.story.owner.fullName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ownerControl.setValue(this.story.owner.fullName);
  }

  updateOwner() {
    this.dialog.open(UpdateOwnerDialogComponent, {
      width: this.updateOwnerDialogWidth,
      height: this.updateOwnerDialogHeight,
      data: {
        storyId: this.story.id,
        selectedOwnerId: this.newSelectedOwner.id,
      } as UpdateOwner,
    });
    this.finishedEditing.emit();
  }

  cancelOwner() {
    this.ownerControl.setValue(this.story.owner.fullName);
    this.finishedEditing.emit();
  }

  displaySelectedOwner(event: MatAutocompleteSelectedEvent) {
    this.newSelectedOwner = this.existingContributors.find((o) => o.fullName === event.option.value);
  }
}

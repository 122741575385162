import { MsalService } from '@azure/msal-angular';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from './services/logging/logging.service';
import { Injectable } from '@angular/core';
import { interceptorSkipHeader, interceptorSkip401RedirectHeader, interceptorSkip409ConflictHeader } from './constants';
import { ClientMessageService } from './services/client-message.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(
    private logger: LoggingService,
    private clientErrorMessage: ClientMessageService,
    private msalService: MsalService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(interceptorSkipHeader)) {
      const headers = request.headers.delete(interceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let errorSide = '';
        if (error.error instanceof ErrorEvent) {
          errorSide = 'client side';
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorSide = 'server side';
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        this.logger.logError(error, {
          'error side': `${errorSide}`,
          message: `${errorMessage}`,
          errorJson: JSON.stringify(error),
        });
        switch (error.status) {
          case 401:
            if (request.headers.has(interceptorSkip401RedirectHeader)) {
              break;
            }
            if (this.msalService.instance.getActiveAccount()) {
              this.logger.logError(error, {
                'error side': `${errorSide}`,
                message: `MSAL is authenticated but got a 401`,
                account: JSON.stringify(this.msalService.instance.getActiveAccount()),
              });
              this.clientErrorMessage.showClientErrorMessage('Sorry, You do not have access to this page');
              this.msalService.loginRedirect();
            }
            break;
          case 409:
            if (request.headers.has(interceptorSkip409ConflictHeader)) {
              break;
            }
          // fall through
          default:
            if (error.error.detail) {
              this.clientErrorMessage.showClientErrorMessage(error.error.detail);
            } else {
              this.clientErrorMessage.showClientErrorMessage('Sorry, there was an error processing your request.');
            }
            break;
        }
        return throwError(error);
      })
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoriesService } from '../../services/stories.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ValueNumberValidator } from '../../../shared/validator-functions';
import { UpdateStoryTitle } from '../../../shared/models/update-story-title';
import { storyTitleMaxCharacterLimit } from 'src/app/shared/constants';

@Component({
  selector: 'app-edit-story-title-dialog',
  templateUrl: './edit-story-title-dialog.component.html',
  styleUrls: ['./edit-story-title-dialog.component.scss'],
})
export class EditStoryTitleDialogComponent implements OnInit {
  storyTitleCharacterLimit = storyTitleMaxCharacterLimit;
  storyTitleForm = new UntypedFormGroup({
    title: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.storyTitleCharacterLimit),
    ]),
    clientName: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.storyTitleCharacterLimit),
    ]),
    value: new UntypedFormControl(0, [Validators.required, Validators.min(1), ValueNumberValidator()]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private editStoryTitleData: UpdateStoryTitle,
    private dialogRef: MatDialogRef<EditStoryTitleDialogComponent>,
    private storiesService: StoriesService
  ) {}

  ngOnInit() {
    this.storyTitleForm.addControl('id', new UntypedFormControl(this.editStoryTitleData.id));
    this.storyTitleForm.setValue(this.editStoryTitleData);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  updateStoryTitle(): void {
    const updateStory = this.storyTitleForm.value as UpdateStoryTitle;
    this.storiesService.updateStoryTitleField(updateStory).subscribe(() => this.dialogRef.close());
  }
}

<div style="padding: 0 25px 5px 25px" *ngIf="isReadOnly && story.getStoryPartValue(storyPart)">
  <h3>{{ storyPartDescriptor[storyPart].Label }}</h3>
  <div class="storyPart" [innerHTML]="md.render(story.getStoryPartValue(storyPart))"></div>
</div>
<mat-card appearance="outlined" class="story-part-wrapper mat-card" *ngIf="!isReadOnly">
  <div (click)="editStoryPart(storyPart)" class="elevate-story-part">
    <div class="story-part-title">
      <h3>
        {{ storyPartDescriptor[storyPart].Label }}
        <mat-icon
          *ngIf="story.getStoryPartValidationMessage(storyPart) && canEdit"
          color="warn"
          matTooltip="{{ story.getStoryPartValidationMessage(storyPart) }}"
          >error
        </mat-icon>
      </h3>
      <mat-icon class="edit-icon">create</mat-icon>
    </div>
    <mat-card-content>
      <div
        class="storyPart"
        *ngIf="story.getStoryPartValue(storyPart) as partValue; else placeholder"
        [innerHTML]="md.render(partValue)"
      ></div>
      <ng-template #placeholder>
        <pre class="story-part-text draft-text" [innerHtml]="storyPartDescriptor[storyPart].Description"></pre>
      </ng-template>
    </mat-card-content>
  </div>
  <div class="revisions-wrapper" *ngIf="hasRevisions(storyPart)">
    <button mat-button class="revisions" (click)="reviewRevisions(storyPart)">
      <mat-icon>history</mat-icon>
      {{ revisionCount(storyPart) }} revisions
    </button>
  </div>
</mat-card>
